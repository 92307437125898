/*
    Creation Time: 2021 - Sep - 26
    Created by:  (hamidrezakk)
    Maintainers:
       1.  HamidrezaKK (hamidrezakks@gmail.com)
    Auditor: HamidrezaKK
    Copyright Qlub_ 2022
*/

@font-face {
    font-family: 'roboto';
    src: url(../assets/fonts/roboto/roboto.eot?) format('eot'), url(../assets/fonts/roboto/roboto.woff) format('woff'),
        url(../assets/fonts/roboto/roboto.woff2) format('woff2');
}

@font-face {
    font-family: 'Inter';
    src: url(../assets/fonts/Inter/Inter-Light.woff) format('woff'),
        url(../assets/fonts/Inter/Inter-Light.woff2) format('woff2');
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'Inter';
    src: url(../assets/fonts/Inter/Inter-Regular.woff) format('woff'),
        url(../assets/fonts/Inter/Inter-Regular.woff2) format('woff2');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Inter';
    src: url(../assets/fonts/Inter/Inter-Medium.woff) format('woff'),
        url(../assets/fonts/Inter/Inter-Medium.woff2) format('woff2');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Inter';
    src: url(../assets/fonts/Inter/Inter-Bold.woff) format('woff'),
        url(../assets/fonts/Inter/Inter-Bold.woff2) format('woff2');
    font-style: normal;
    font-weight: 700;
}

html,
body {
    direction: ltr;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scrollbar-width: none;

    .css-iztilb {
        margin: 0;
    }

    .root-container {
        background-image: url(/images/pattern.svg);
        background-color: var(--iris);
    }
}

html[dir='rtl'] body {
    direction: rtl;
}

* {
    font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

html[lang='jp'] {
    * {
        font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
            'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    }
}

html[lang='ar'] {
    * {
        font-family: -apple-system, 'system-ui', 'Segoe UI', Roboto, 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
            'Droid Sans', 'Helvetica Neue', sans-serif;
    }
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.section {
    max-width: 1280px;
    margin: 0 auto;
}

.sectionBig {
    max-width: 1920px;
    margin: 0 auto;
}

.mainContainerPadding {
    padding-top: 64px;
    padding-bottom: 12px;
    min-height: calc((var(--vh, 1vh) * 100) - 90px);
}

.mainContainer {
    padding-top: 12px;
    padding-bottom: 12px;
    min-height: calc((var(--vh, 1vh) * 100) - 90px);
}

.kkLoadingContainer {
    min-height: calc((var(--vh, 1vh) * 100) - 90px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

button a {
    width: 100%;
    height: 100%;
    display: block;
}

.ptr,
.ptr__children {
    overflow: unset !important;
}

#dropOpacity .MuiBackdrop-root {
    opacity: 0 !important;
}
#dropOpacity .MuiDrawer-paper {
    box-shadow: 0 -4px 15px 0 rgba(0, 0, 0, 0.1) !important;
    border-radius: 30px 30px 0 0;
}

html {
    --white: #fff;

    --black: #060201;
    --black-focus: rgba(0, 0, 0, 0.12);
    --black-hover: rgba(0, 0, 0, 0.04);
    --black-pressed: rgba(0, 0, 0, 0.1);
    --black-dragged: rgba(0, 0, 0, 0.08);
    --black-selected: rgba(0, 0, 0, 0.08);

    --onColorColors-highEmphasis: #f5f5f5;
    --onColorColors-mediumEmphasis: rgba(255, 255, 255, 0.74);
    --onColorColors-disabled: rgba(255, 255, 255, 0.38);

    --onSurfaceColors-highEmphasis: #1a1c23;
    --onSurfaceColors-mediumEmphasis: #616475;
    --onSurfaceColors-disabled: #9b9eab;

    --iris: #060201;
    --iris-hover: rgba(0, 0, 0, 0.04);
    --iris-light: rgba(0, 0, 0, 0.08);
    --iris-lighter: rgba(0, 0, 0, 0.08);
    --iris-focus: rgba(0, 0, 0, 0.12);
    --iris-pressed: rgba(0, 0, 0, 0.1);
    --iris-dragged: rgba(0, 0, 0, 0.08);
    --iris-selected: rgba(0, 0, 0, 0.08);
    --iris-dark: #060201;
    --iris-darker: #060201;

    --iris-light: var(--onColorColors-highEmphasis);
    --iris-hover: var(--onColorColors-disabled);
    --iris-light: var(--onColorColors-mediumEmphasis);
    --iris-lighter: var(--onColorColors-mediumEmphasis);
    --iris-focus: var(--onColorColors-disabled);
    --iris-pressed: var(--onColorColors-disabled);
    --iris-dragged: var(--onColorColors-disabled);
    --iris-selected: var(--onColorColors-disabled);

    --dim: #ebecf2;
    --disabled-button: #f3f3f3;

    --pending: #1995fd;
    --pending-light: rgba(65, 142, 229, 0.2);

    --destructive: #fcedec;
    --destructive-hover: #fef7f6;
    --destructive-focus: #fbe4e3;
    --destructive-pressed: #fce8e8;
    --destructive-text: #df1d17;

    --danger: #df1d17;
    --danger-transparent: #df1d171a;

    --success: #22a958;
    --success-transparent: #22a9581a;

    --validation-color: var(--black);
    --validation-failed-color: #df1d17;
}

html[loaded='true'] {
    --iris: #7d00d4;
    --iris-hover: rgba(125, 0, 212, 0.04);
    --iris-lighter: #9e2feb;
    --iris-focus: #7d00d41f;
    --iris-pressed: #7d00d41a;
    --iris-dragged: #7d00d414;
    --iris-selected: #7d00d414;

    --iris-light: rgba(125, 0, 212, 0.08);
    --iris-light-pressed: rgba(125, 0, 212, 0.1);
    --iris-light-selected: rgba(125, 0, 212, 0.08);
    --iris-light-focus: rgba(125, 0, 212, 0.12);
    --iris-light-dragged: rgba(125, 0, 212, 0.08);
    --iris-light-text: #7d00d4;

    --validation-color: #7d00d4;

    --iris-dark: #5b009a;
    --iris-darker: #40006c;

    --dynamic-purple: #f9f1ff;
    --dynamic-yellow: #faf9eb;
    --dynamic-blue: #ebf9fa;
    --dynamic-green: #ebfaec;
}

html[mode='black'] {
    --iris: var(--black);
    --iris-hover: var(--black-hover);
    --iris-light: var(--black-selected);
    --iris-lighter: var(--black-selected);
    --iris-focus: var(--black-focus);
    --iris-pressed: var(--black-pressed);
    --iris-dragged: var(--black-dragged);
    --iris-selected: var(--black-selected);

    --iris-light: var(--onColorColors-highEmphasis);
    --iris-hover: var(--onColorColors-disabled);
    --iris-light: var(--onColorColors-mediumEmphasis);
    --iris-lighter: var(--onColorColors-mediumEmphasis);
    --iris-focus: var(--onColorColors-disabled);
    --iris-pressed: var(--onColorColors-disabled);
    --iris-dragged: var(--onColorColors-disabled);
    --iris-selected: var(--onColorColors-disabled);

    --validation-color: var(--black);
}

html[mode='green'] {
    --iris: #0b6f42;
    --iris-hover: rgba(11, 111, 66, 0.04);
    --iris-lighter: #1c8c59;
    --iris-focus: #0b6f421f;
    --iris-pressed: #0b6f421a;
    --iris-dragged: #0b6f4214;
    --iris-selected: #0b6f4214;

    --iris-light: rgba(11, 111, 66, 0.08);
    --iris-light-pressed: rgba(11, 111, 66, 0.1);
    --iris-light-selected: rgba(11, 111, 66, 0.08);
    --iris-light-focus: rgba(11, 111, 66, 0.12);
    --iris-light-dragged: rgba(11, 111, 66, 0.08);
    --iris-light-text: #0b6f42;

    --validation-color: #0b6f42;

    --iris-dark: #094e32;
    --iris-darker: #073a26;
}

html[mode='blue'] {
    --iris: #0055d4;
    --iris-hover: rgba(0, 85, 212, 0.04);
    --iris-lighter: #206de7;
    --iris-focus: #0055d41f;
    --iris-pressed: #0055d41a;
    --iris-dragged: #0055d414;
    --iris-selected: #0055d414;

    --iris-light: rgba(0, 85, 212, 0.08);
    --iris-light-pressed: rgba(0, 85, 212, 0.1);
    --iris-light-selected: rgba(0, 85, 212, 0.08);
    --iris-light-focus: rgba(0, 85, 212, 0.12);
    --iris-light-dragged: rgba(0, 85, 212, 0.08);
    --iris-light-text: #0055d4;

    --validation-color: #0055d4;

    --iris-dark: #0042a5;
    --iris-darker: #003175;
}

html[mode='pink'] {
    --iris: #d20093;
    --iris-hover: rgba(210, 0, 147, 0.04);
    --iris-lighter: #f11fb1;
    --iris-focus: #d200931f;
    --iris-pressed: #d200931a;
    --iris-dragged: #d2009314;
    --iris-selected: #d2009314;

    --iris-light: rgba(210, 0, 147, 0.08);
    --iris-light-pressed: rgba(210, 0, 147, 0.1);
    --iris-light-selected: rgba(210, 0, 147, 0.08);
    --iris-light-focus: rgba(210, 0, 147, 0.12);
    --iris-light-dragged: rgba(210, 0, 147, 0.08);
    --iris-light-text: #d20093;

    --validation-color: #d20093;

    --iris-dark: #a10074;
    --iris-darker: #730052;
}

html[mode='darkred'] {
    --iris: #940f00;
    --iris-hover: rgba(148, 15, 0, 0.04);
    --iris-lighter: #b72412;
    --iris-focus: #940f001f;
    --iris-pressed: #940f001a;
    --iris-dragged: #940f0014;
    --iris-selected: #940f0014;

    --iris-light: rgba(148, 15, 0, 0.08);
    --iris-light-pressed: rgba(148, 15, 0, 0.1);
    --iris-light-selected: rgba(148, 15, 0, 0.08);
    --iris-light-focus: rgba(148, 15, 0, 0.12);
    --iris-light-dragged: rgba(148, 15, 0, 0.08);
    --iris-light-text: #940f00;

    --validation-color: #940f00;

    --iris-dark: #700b00;
    --iris-darker: #500800;
}

html[mode='orange'] {
    --iris: #f25600;
    --iris-hover: rgba(242, 86, 0, 0.04);
    --iris-lighter: #f25600cc;
    --iris-focus: #f256001f;
    --iris-pressed: #f256001a;
    --iris-dragged: #f2560014;
    --iris-selected: #f2560014;

    --iris-light: rgba(242, 86, 0, 0.08);
    --iris-light-pressed: rgba(242, 86, 0, 0.1);
    --iris-light-selected: rgba(242, 86, 0, 0.08);
    --iris-light-focus: rgba(242, 86, 0, 0.12);
    --iris-light-dragged: rgba(242, 86, 0, 0.08);
    --iris-light-text: #f25600;

    --validation-color: #f25600;

    --iris-dark: #c04400;
    --iris-darker: #8f3400;
}

html[mode='darkgold'] {
    --iris: #958035;
    --iris-hover: rgba(154, 128, 53, 0.04);
    --iris-lighter: #958035cc;
    --iris-focus: #9580351f;
    --iris-pressed: #9580351a;
    --iris-dragged: #95803514;
    --iris-selected: #95803514;

    --iris-light: rgba(154, 128, 53, 0.08);
    --iris-light-pressed: rgba(154, 128, 53, 0.1);
    --iris-light-selected: rgba(154, 128, 53, 0.08);
    --iris-light-focus: rgba(154, 128, 53, 0.12);
    --iris-light-dragged: rgba(154, 128, 53, 0.08);
    --iris-light-text: #958035;

    --validation-color: #958035;

    --iris-dark: #766427;
    --iris-darker: #5a4c1e;
}
